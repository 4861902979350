import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: 'My motto is... <b>Be Awesome. Do Awesome.</b>',
  paraTwo: `I'm a full stack developer who is passionate about building products and teams.
  I have built IoT and Fintech solutions for clients ranging from startups to large enterprises.`,
  paraThree: `Hit me up if you're working on soltuions in IoT, Fintech, Devtools, or any thing interesting really.`,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${pageText.paraTwo} ${stripTags(pageText.paraThree)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Frank', 'Odongkara', 'Jaker', 'Software Developer', 'ReactJS', 'NodeJS', 'IOT', 'Kampala', 'Uganda']}
        />
        <h1 className="titleSeparate">Hi, I&apos;m Frank</h1>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraOne)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraThree)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <AboutTile
            img="coffee-cup.png"
            alt="coffee image"
            textH4="I love drinking"
            textH3="Coffee"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <AboutTile
            img="dog.png"
            alt="dog image"
            textH4="Always petting"
            textH3="Dogs"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <AboutTile
            img="books.png"
            alt="book image"
            textH4="You can gift me"
            textH3="Books"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <AboutTile
            img="art.png"
            alt="art image"
            textH4="I love experimental"
            textH3="Art"
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
