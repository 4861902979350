import React from 'react';
import { Row, Col } from 'antd';
import ProgressBar from '../../Progress';

const SkillsProgress = () => (
  <div>
    <h2>I work with...</h2>
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={8}>
        <h3>Backend</h3>
        <ProgressBar
          percent={100}
          text="Nodejs"
        />
        <ProgressBar
          percent={100}
          text="Golang"
        />
        <ProgressBar
          percent={100}
          text="Python"
        />
      </Col>
      <Col xs={24} sm={24} md={8}>
        <h3>Frontend</h3>
        <ProgressBar
          percent={100}
          text="ReactJS"
        />
        <ProgressBar
          percent={100}
          text="Angular"
        />
        <ProgressBar
          percent={100}
          text="jQuery"
        />
      </Col>
      <Col xs={24} sm={24} md={8}>
        <h3>Mobile</h3>
        <ProgressBar
          percent={100}
          text="React Native"
        />
        <ProgressBar
          percent={100}
          text="Flutter"
        />
        <ProgressBar
          percent={100}
          text="Kotlin"
        />
      </Col>
    </Row>
  </div>
);

export default SkillsProgress;
